<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col offset-md="1" offset-lg="2">
        <Container :paddedTopBottom="false">
          <h2>Samenwerken is <em>kracht</em></h2>
          <p>
            Na een aantal jaren van fijne samenwerking, hebben Klaas Osteopaat
            en Osteopathie Daan de Bruijn begin 2021 besloten deze te
            intensiveren. Hieruit is Osteo’s ontstaan. Osteo’s bestaat uit een
            groep zelfstandige osteopaten die veel waarde hechten aan
            samenwerking. Op deze manier houden wij elkaar scherp en leveren wij
            u de best mogelijke zorg.
          </p>
        </Container>
        <Container :paddedTopBottom="false">
          <h2>Team <em>Osteo's</em></h2>
        </Container>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters>
          <v-col class="team-member" :cols="cols" :sm="sm" :md="md" :lg="lg">
            <v-row no-gutters>
              <v-col><img src="../assets/team/daan.png" alt="Daan"/></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                Daan de Bruijn <br />
                <span>Osteopaat</span>
                <hr />
                <v-btn
                  aria-label="Lees meer over Daan"
                  icon
                  to="/over-ons/daan"
                >
                  <v-icon>mdi-page-next</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="team-member" :cols="cols" :sm="sm" :md="md" :lg="lg">
            <v-row no-gutters>
              <v-col><img src="../assets/team/klaas.png" alt="Klaas"/></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                Klaas van der Knaap <br />
                <span>Osteopaat</span>
                <hr />
                <v-btn
                  aria-label="Lees meer over Klaas"
                  icon
                  to="/over-ons/klaas"
                >
                  <v-icon>mdi-page-next</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="team-member" :cols="cols" :sm="sm" :md="md" :lg="lg">
            <v-row no-gutters>
              <v-col><img src="../assets/team/kim.png" alt="Kim"/></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                Kim Henneart <br />
                <span>Osteopaat</span>
                <hr />
                <v-btn aria-label="Lees meer over Kim" icon to="/over-ons/kim">
                  <v-icon>mdi-page-next</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="team-member" :cols="cols" :sm="sm" :md="md" :lg="lg">
            <v-row no-gutters>
              <v-col>
                <img src="../assets/team/liesbeth.png" alt="Liesbeth" />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                Liesbeth Brinkman <br />
                <span>Osteopaat</span>
                <hr />
                <v-btn
                  aria-label="Lees meer over Liesbeth"
                  icon
                  to="/over-ons/liesbeth"
                >
                  <v-icon>mdi-page-next</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="team-member" :cols="cols" :sm="sm" :md="md" :lg="lg">
            <v-row no-gutters>
              <v-col><img src="../assets/team/dinesh.png" alt="Dinesh"/></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                Dinesh Jankie <br />
                <span>Osteopaat</span>
                <hr />
                <v-btn
                  aria-label="Lees meer over Dinesh"
                  icon
                  to="/over-ons/dinesh"
                >
                  <v-icon>mdi-page-next</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="team-member" :cols="cols" :sm="sm" :md="md" :lg="lg">
            <v-row no-gutters>
              <v-col>
                <img src="../assets/team/ryanne.png" alt="Ryanne" />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                Ryanne van Rossen<br />
                <span>Osteopaat</span>
                <hr />
                <v-btn
                  aria-label="Lees meer over Ryanne"
                  icon
                  to="/over-ons/ryanne"
                >
                  <v-icon>mdi-page-next</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="team-member" :cols="cols" :sm="sm" :md="md" :lg="lg">
            <v-row no-gutters>
              <v-col>
                <img src="../assets/team/leonore.png" alt="Leonore" />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                Leonore Voets<br />
                <span>Osteopaat</span>
                <hr />
                <v-btn
                  aria-label="Lees meer over Leonore"
                  icon
                  to="/over-ons/leonore"
                >
                  <v-icon>mdi-page-next</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="team-member" :cols="cols" :sm="sm" :md="md" :lg="lg">
            <v-row no-gutters>
              <v-col> </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col> </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col offset-md="1" offset-lg="2">
        <Container :paddedTopBottom="false">
          <h2>Partners</h2>
          <v-row class="partners">
            <v-col cols="12" sm="6" md="4" lg="2">
              <a
                href="https://fortiorfysiotherapie.nl/"
                target="new"
                aria-label="Fortior fysiotherapie"
              >
                <img
                  alt="Fortior fysiotherapie"
                  src="../assets/partners/logo-fortiorfysiotherapie.png"
                />
              </a>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <a
                href="https://www.fysiotherapieplus.nl/"
                target="new"
                aria-label="Fysiotherapie plus"
              >
                <img
                  alt="Fysiotherapie plus"
                  src="../assets/partners/logo-fysiotherapieplus.jpg"
                />
              </a>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <a
                href="https://www.logoplus-rotterdam.nl/"
                target="new"
                aria-label="Logoplus"
              >
                <img
                  alt="Logoplus"
                  src="../assets/partners/logo-logoplus.png"
                />
              </a>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <a
                href="https://www.smcspartarotterdam.nl/"
                target="new"
                aria-label="SMC Sparta"
              >
                <img alt="SMC Sparta" src="../assets/partners/logo-smc.png" />
              </a>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <a
                href="https://www.voetenreflextherapie.nl/"
                target="new"
                aria-label="Voetenreflextherapie"
              >
                <img
                  alt="Voeten reflex therapie"
                  src="../assets/partners/logo-voetreflextherapie.jpg"
                />
              </a>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <a
                href="https://verloskundige-pijnacker.nl/"
                target="new"
                aria-label="Verloskundige pijnacker"
              >
                <img
                  alt="Verloskundige pijnacker"
                  src="../assets/partners/logo-verloskundige-pijnacker.webp"
                />
              </a>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <a
                href="https://www.my-balance.nu/"
                target="new"
                aria-label="My Balance"
              >
                <img
                  alt="My Balance"
                  src="../assets/partners/logo-my-balance.jpg"
                />
              </a>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <a
                href="https://www.paktalent.nl/"
                target="new"
                aria-label="Paktalent"
              >
                <img
                  alt="Paktalent"
                  src="../assets/partners/logo-paktalent.png"
                />
              </a>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <a
                href="https://www.bettinevanderheijden.nl"
                target="new"
                aria-label="Voeding en Vitaliteit"
              >
                <img
                  alt="Voeding en Vitaliteit"
                  src="../assets/partners/bettine.jpg"
                />
              </a>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <a href="https://www.metemme.nl/" target="new" aria-label="Emme">
                <img alt="Emme" src="../assets/partners/logo-emme.png" />
              </a>
            </v-col>
          </v-row>
        </Container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Container from "../components/Container.vue";
export default {
  name: "OverOns",
  components: { Container },
  data: () => {
    return {
      cols: 12,
      sm: 6,
      md: 3,
      lg: 3
    };
  }
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 2rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 1rem;
  em {
    font-style: normal;
    color: #7f8274;
  }
}
.team-member {
  text-align: center;
  margin-bottom: 2rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(202, 207, 184, 1) 25%,
    rgba(202, 207, 184, 1) 64%,
    rgba(227, 227, 227, 1) 64%,
    rgba(227, 227, 227, 1) 100%
  );
  img {
    height: 190px;
    margin-bottom: -1px;
  }
  span {
    font-size: 0.9rem;
  }
  hr {
    width: 65px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border: none;
    border-top: solid 1px #7f8274;
  }
}
.partners {
  img {
    max-width: 100%;
    margin-bottom: 1rem;
  }
}
</style>
